import axios from "axios";
export class basicData {
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;
        this.headers = {
            user: this.user,
            company: this.id_company,
            branch: this.id_branch,
            token: this.token        
        }
    }
    
    async getFamilies() {
        let response = await axios.get("tpv/family", {
            headers: this.headers
        });
        return response.data;
    }

    async getProducts() {
        let response = await axios.get("tpv/products/sale", {
            headers: this.headers
        });
        return response.data;
    }
    
    async getPrinters() {
        let response = await axios.get("tpv/printer", {
            headers: this.headers
        });
        return response.data;
    }

    async getPriceList() {
        let response = await axios.get("tpv/pricelist", {
            headers: this.headers
        });
        return response.data;
    }

    async getDeliveryArea() {
        let response = await axios.get("tpv/deliveryarea", {
            headers: this.headers
        });
        return response.data;
    }
    
    async getTables() {
        let response = await axios.get("tpv/table", {
            headers: this.headers
        });
        return response.data;
    }

    async getWaiters() {
        let response = await axios.get("tpv/waiter", {
            headers: this.headers
        });
        return response.data;
    }

    async getCustomers() {
        let response = await axios.get("tpv/customer", {
            headers: this.headers
        });
        return response.data;
    }


    async getPaymentTypes() {
        let response = await axios.get("tpv/paymenttype", {
            headers: this.headers
        });
        return response.data;
    }

    
    async getServices() {
        let response = await axios.get("tpv/services", {
            headers: this.headers
        });
        return response.data;
    }

    async getSessionRegister() {
        let response = await axios.get("tpv/register/session", {
            headers: this.headers
        });
        return response.data;
    }

    async getTickets(id_register) {
        let response = await axios.get("tpv/ticket/" + id_register, {
            headers: this.headers
        });
        return response.data;
    }

}