import axios from "axios";
// import printer_payload from "./printer_payload";
export class ticket
{
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;
        this.id	= null;
        this.id_register	= null;
        this.id_number	= null;
        this.date	= null;
        this.id_customer	= null;
        this.id_employer	= null;
        this.subtotal	= 0.00;
        this.iva	= 0.00;
        this.ieps	= 0.00;
        this.total	= 0.00;
        this.discount_percentage	= 0.00;
        this.id_group	= null;
        this.note	= null;
        this.tpv_user	= null;
        this.status	= null;
        this.created_by	= null;
        this.created	= null;
        this.modified_by	= null;
        this.modified	= null;
        this.modified_terminal	= null;
        this.created_terminal	= null;
        this.id_service_type	= 1;
        this.tip	= 0.00;
        this.table_name	= null;
        this.is_invoiceable	= false;
        this.order_number	= null;
        this.cancelation_return	= null;
        this.id_original_register	= null;
        this.uuid	= null;
        this.cancel_date	= null;
        this.discount_date	= null;
        this.end_date	= null;
        this.prepare_date	= null;
        this.id_discount	= null;
        this.id_cancelation_reason	= null;
        this.id_discount_reason	= null;
        this.cancel_comment	= null;
        this.discount_reference	= null;
        this.cancel_user	= null;
        this.discount_user	= null;
        this.discount_type	= null;
        this.shipping	= 0.00;
        this.id_waiter	= null;
        this.id_invoice	= null;
        this.id_sale_type	= null;
        this.pay_with	= 0.00;
        this.id_table	= null;
        this.discount_import = 0.00;
        this.price_list = 0;
        this.items = [];
        this.payments = [];
    }

    async register() {
        this.total = 487;
        let response = await axios.post("tpv/ticket/register", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async delete() {
        let response = await axios.delete("tpv/ticket/" + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async cancel() {
        let response = await axios.post("tpv/ticket/cancel/" + this.id, this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    // print() {
    //     let printer_data = new printer_payload();
    //     printer_data.taxInfo = "X1100X1X0X";
    //     printer_data.address = "CALLE DE PRUEBA #326";
    //     printer_data.email = "prueba@gmail.com";
    //     printer_data.date = this.ticket.date;
    //     printer_data.id = ticket.id_number;
    //     printer_data.footer = "Gracias por su compra!";
    //     printer_data.phoneNumber = "811 458 8754";
    //     printer_data.subtotal = ticket.subtotal;
    //     printer_data.total = ticket.total;
    //     printer_data.taxes = ticket.iva;
    //     if (this.id_customer) {
    //         let customer = this.customers.find(x => x.id == ticket.id_customer);
    //         printer_data.customerInfo.address = customer.address;
    //         printer_data.customerInfo.email = customer.email;
    //         printer_data.customerInfo.name = customer.name;
    //         printer_data.customerInfo.phoneNumber = customer.phone;
    //         printer_data.customerInfo.taxInfo = customer.rfc;
    //     }
    //     this.items.forEach(item => {
    //     printer_data.products.push({
    //             quantity: item.quantity, 
    //             description: item.name, 
    //             sku: item.key_name, 
    //             price: item.unit_price, 
    //             total: item.total
    //         });
    //     });
    //     this.payments.forEach(payment => {
    //         printer_data.payments.push({
    //             name: payment.name,
    //             amount: payment.amount
    //         });
    //     })
    // }

    async pay() {
        let response = await axios.post("tpv/ticket/pay", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async prepare() {
        let response = await axios.post("tpv/ticket/prepare", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }


    
    async search(date, number, paid) {
        let response = await axios.post("tpv/ticket/search", {
            date: date,
            number: number,
            paid: paid
        }, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async invoiceable(initial_date, final_date) {
        let response = await axios.post("tpv/ticket/invoiceable", {
            initial_date: initial_date,
            final_date: final_date
        }, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async history(date) {
        let response = await axios.post("tpv/ticket/history", {
            date: date
        }, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async pending() {
        let response = await axios.post("tpv/ticket/pending", null, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async prepareFinish(id) {
        let response = await axios.post("tpv/ticket/prepare/finish/" + id, null, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async prepareFinishItem(id) {
        let response = await axios.post("tpv/ticket/prepare/finish/item/" + id, null, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

}